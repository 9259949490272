<template>
  <div>
    <div class="items-center justify-center">
      <b-row>
        <b-col cols="12" md="4">
          <b-card class="">
            <p style="font-weight: bold; color: #454a62">
              {{ $t("Online Consultation Latest Price") }}
            </p>
            <p class="pl-3 pr-3">{{ $t("PricingAvailNote") }}</p>

            <b-row>
              <b-col cols="12" md="6">
                <vs-input type="number" v-model="priceModel.Price"
              /></b-col>
              <b-col cols="12" md="6">
                <v-select
                  label="Name"
                  v-model="priceModel.CurrencyID"
                  :options="currencies"
                  :placeholder="$t('Currency')"
                  :reduce="(obj) => obj.ID"
              /></b-col>

              <!-- <p class="mt-2" style="font-size:30px">$</p> -->
            </b-row>
          </b-card>

          <b-card>
            <div>
              <p style="font-weight: bold; color: #454a62">
                {{ $t("ChooseDays") }}
              </p>
            </div>
            <!-- days -->
            <b-row>
              <b-button
                size="small"
                @click="model.Sunday = !model.Sunday"
                class="m-1"
                :variant="model.Sunday ? 'primary' : 'white'"
                :style="[model.Sunday ? {} : { border: ' 2px solid #1F1F1F' }]"
                style="border-radius: 34px; opacity: 1; border-color: black"
                ><span
                  :style="[
                    model.Sunday ? { color: 'white' } : { color: 'black' },
                  ]"
                >
                  {{ $t("Sun") }}</span
                >
              </b-button>
              <b-button
                size="small"
                @click="model.Monday = !model.Monday"
                :variant="model.Monday ? 'primary' : 'white'"
                :style="[model.Monday ? {} : { border: ' 2px solid #1F1F1F' }]"
                style="border-radius: 34px; opacity: 1"
                class="mt-1 mb-1"
                ><span
                  :style="[
                    model.Monday ? { color: 'white' } : { color: 'black' },
                  ]"
                >
                  {{ $t("Mon") }}</span
                >
              </b-button>
              <b-button
                size="small"
                @click="model.Tuesday = !model.Tuesday"
                :variant="model.Tuesday ? 'primary' : 'white'"
                :style="[model.Tuesday ? {} : { border: ' 2px solid #1F1F1F' }]"
                style="border-radius: 34px; opacity: 1"
                class="m-1"
                ><span
                  :style="[
                    model.Tuesday ? { color: 'white' } : { color: 'black' },
                  ]"
                >
                  {{ $t("Tues") }}</span
                >
              </b-button>
              <b-button
                class="mt-1 mb-1"
                size="small"
                @click="model.Wednesday = !model.Wednesday"
                :variant="model.Wednesday ? 'primary' : 'white'"
                :style="[
                  model.Wednesday ? {} : { border: ' 2px solid #1F1F1F' },
                ]"
                style="border-radius: 34px; opacity: 1"
                ><span
                  :style="[
                    model.Wednesday ? { color: 'white' } : { color: 'black' },
                  ]"
                >
                  {{ $t("Wednes") }}</span
                >
              </b-button>
              <b-button
                size="small"
                @click="model.Thursday = !model.Thursday"
                :variant="model.Thursday ? 'primary' : 'white'"
                :style="[
                  model.Thursday ? {} : { border: ' 2px solid #1F1F1F' },
                ]"
                style="border-radius: 34px; opacity: 1"
                class="m-1"
                ><span
                  :style="[
                    model.Thursday ? { color: 'white' } : { color: 'black' },
                  ]"
                >
                  {{ $t("Thurs") }}</span
                >
              </b-button>
              <b-button
                class="mt-1 mb-1"
                size="small"
                @click="model.Friday = !model.Friday"
                :variant="model.Friday ? 'primary' : 'white'"
                :style="[model.Friday ? {} : { border: ' 2px solid #1F1F1F' }]"
                style="border-radius: 34px; opacity: 1"
                ><span
                  :style="[
                    model.Friday ? { color: 'white' } : { color: 'black' },
                  ]"
                >
                  {{ $t("Fri") }}</span
                >
              </b-button>
              <b-button
                size="small"
                @click="model.Saturday = !model.Saturday"
                :variant="model.Saturday ? 'primary' : 'white'"
                :style="[
                  model.Saturday ? {} : { border: ' 2px solid #1F1F1F' },
                ]"
                style="border-radius: 34px; opacity: 1"
                class="m-1"
                ><span
                  :style="[
                    model.Saturday ? { color: 'white' } : { color: 'black' },
                  ]"
                >
                  {{ $t("Sat") }}</span
                >
              </b-button>
            </b-row>
          </b-card>

          <b-card>
            <b-col >
              <div>
                <p style="font-weight: bold; color: #454a62">
                  {{ $t("ChooseMonthsoptional") }}*
                </p>
              </div>
              <!-- month -->
              <b-row>
                <b-button
                  size="small"
                  style="border-radius: 34px; opacity: 1"
                  :variant="monthsModel.All ? 'primary' : 'white'"
                  :style="[
                    monthsModel.All ? {} : { border: ' 2px solid #1F1F1F' },
                  ]"
                  @click="initAllMonthes()"
                  class="m-1"
                  ><span
                    :style="[
                      monthsModel.All ? { color: 'white' } : { color: 'black' },
                    ]"
                  >
                    {{ $t("All") }}</span
                  >
                </b-button>
                <b-button
                  size="small"
                  style="border-radius: 20px; opacity: 1"
                  :variant="
                    monthsModel.January || monthsModel.All ? 'primary' : 'white'
                  "
                  :style="[
                    monthsModel.January ? {} : { border: ' 2px solid #1F1F1F' },
                  ]"
                  @click="
                    monthsModel.January = !monthsModel.January;
                    monthsModel.All = false;
                    viewMonth();
                  "
                  class="mt-1 mb-1"
                  ><span
                    :style="[
                      monthsModel.January || monthsModel.All
                        ? { color: 'white' }
                        : { color: 'black' },
                    ]"
                  >
                    {{ $t("January") }}</span
                  >
                </b-button>
                <b-button
                  size="small"
                  style="border-radius: 34px; opacity: 1"
                  :variant="
                    monthsModel.February || monthsModel.All
                      ? 'primary'
                      : 'white'
                  "
                  :style="[
                    monthsModel.February
                      ? {}
                      : { border: ' 2px solid #1F1F1F' },
                  ]"
                  @click="
                    monthsModel.February = !monthsModel.February;
                    monthsModel.All = false;
                    viewMonth();
                  "
                  class="m-1"
                  ><span
                    :style="[
                      monthsModel.February || monthsModel.All
                        ? { color: 'white' }
                        : { color: 'black' },
                    ]"
                  >
                    {{ $t("February") }}</span
                  >
                </b-button>
                <b-button
                  size="small"
                  style="border-radius: 34px; opacity: 1"
                  :variant="
                    monthsModel.Mars || monthsModel.All ? 'primary' : 'white'
                  "
                  :style="[
                    monthsModel.Mars ? {} : { border: ' 2px solid #1F1F1F' },
                  ]"
                  @click="
                    monthsModel.Mars = !monthsModel.Mars;
                    monthsModel.All = false;
                    viewMonth();
                  "
                  class="mt-1 mb-1"
                  ><span
                    :style="[
                      monthsModel.Mars || monthsModel.All
                        ? { color: 'white' }
                        : { color: 'black' },
                    ]"
                  >
                    {{ $t("Mars") }}</span
                  >
                </b-button>
                <b-button
                  size="small"
                  style="border-radius: 34px; opacity: 1"
                  :variant="
                    monthsModel.April || monthsModel.All ? 'primary' : 'white'
                  "
                  :style="[
                    monthsModel.April ? {} : { border: ' 2px solid #1F1F1F' },
                  ]"
                  @click="
                    monthsModel.April = !monthsModel.April;
                    monthsModel.All = false;
                    viewMonth();
                  "
                  class="m-1"
                  ><span
                    :style="[
                      monthsModel.April || monthsModel.All
                        ? { color: 'white' }
                        : { color: 'black' },
                    ]"
                  >
                    {{ $t("April") }}</span
                  >
                </b-button>
                <b-button
                  size="small"
                  style="border-radius: 34px; opacity: 1"
                  :variant="
                    monthsModel.May || monthsModel.All ? 'primary' : 'white'
                  "
                  :style="[
                    monthsModel.May ? {} : { border: ' 2px solid #1F1F1F' },
                  ]"
                  @click="
                    monthsModel.May = !monthsModel.May;
                    monthsModel.All = false;
                    viewMonth();
                  "
                  class="mt-1 mb-1"
                  ><span
                    :style="[
                      monthsModel.May || monthsModel.All
                        ? { color: 'white' }
                        : { color: 'black' },
                    ]"
                  >
                    {{ $t("May") }}</span
                  >
                </b-button>
                <b-button
                  size="small"
                  style="border-radius: 34px; opacity: 1"
                  :variant="
                    monthsModel.June || monthsModel.All ? 'primary' : 'white'
                  "
                  :style="[
                    monthsModel.June ? {} : { border: ' 2px solid #1F1F1F' },
                  ]"
                  @click="
                    monthsModel.June = !monthsModel.June;
                    monthsModel.All = false;
                    viewMonth();
                  "
                  class="m-1"
                  ><span
                    :style="[
                      monthsModel.June || monthsModel.All
                        ? { color: 'white' }
                        : { color: 'black' },
                    ]"
                  >
                    {{ $t("June") }}</span
                  >
                </b-button>
                <b-button
                  size="small"
                  style="border-radius: 34px; opacity: 1"
                  :variant="
                    monthsModel.July || monthsModel.All ? 'primary' : 'white'
                  "
                  :style="[
                    monthsModel.July ? {} : { border: ' 2px solid #1F1F1F' },
                  ]"
                  @click="
                    monthsModel.July = !monthsModel.July;
                    monthsModel.All = false;
                    viewMonth();
                  "
                  class="mt-1 mb-1"
                  ><span
                    :style="[
                      monthsModel.July || monthsModel.All
                        ? { color: 'white' }
                        : { color: 'black' },
                    ]"
                  >
                    {{ $t("July") }}</span
                  >
                </b-button>
                <b-button
                  size="small"
                  style="border-radius: 34px; opacity: 1"
                  :variant="
                    monthsModel.August || monthsModel.All ? 'primary' : 'white'
                  "
                  :style="[
                    monthsModel.August ? {} : { border: ' 2px solid #1F1F1F' },
                  ]"
                  @click="
                    monthsModel.August = !monthsModel.August;
                    monthsModel.All = false;
                    viewMonth();
                  "
                  class="m-1"
                  ><span
                    :style="[
                      monthsModel.August || monthsModel.All
                        ? { color: 'white' }
                        : { color: 'black' },
                    ]"
                  >
                    {{ $t("August") }}</span
                  >
                </b-button>
                <b-button
                  size="small"
                  style="border-radius: 34px; opacity: 1"
                  :variant="
                    monthsModel.September || monthsModel.All
                      ? 'primary'
                      : 'white'
                  "
                  :style="[
                    monthsModel.September
                      ? {}
                      : { border: ' 2px solid #1F1F1F' },
                  ]"
                  @click="
                    monthsModel.September = !monthsModel.September;
                    monthsModel.All = false;
                    viewMonth();
                  "
                  class="mt-1 mb-1"
                  ><span
                    :style="[
                      monthsModel.September || monthsModel.All
                        ? { color: 'white' }
                        : { color: 'black' },
                    ]"
                  >
                    {{ $t("September") }}</span
                  >
                </b-button>
                <b-button
                  size="small"
                  style="border-radius: 34px; opacity: 1"
                  :variant="
                    monthsModel.October || monthsModel.All ? 'primary' : 'white'
                  "
                  :style="[
                    monthsModel.October ? {} : { border: ' 2px solid #1F1F1F' },
                  ]"
                  @click="
                    monthsModel.October = !monthsModel.October;
                    monthsModel.All = false;
                    viewMonth();
                  "
                  class="m-1"
                  ><span
                    :style="[
                      monthsModel.October || monthsModel.All
                        ? { color: 'white' }
                        : { color: 'black' },
                    ]"
                  >
                    {{ $t("October") }}</span
                  >
                </b-button>
                <b-button
                  size="small"
                  style="border-radius: 34px; opacity: 1"
                  :variant="
                    monthsModel.November || monthsModel.All
                      ? 'primary'
                      : 'white'
                  "
                  :style="[
                    monthsModel.November
                      ? {}
                      : { border: ' 2px solid #1F1F1F' },
                  ]"
                  @click="
                    monthsModel.November = !monthsModel.November;
                    monthsModel.All = false;
                    viewMonth();
                  "
                  class="mt-1 mb-1"
                  ><span
                    :style="[
                      monthsModel.November || monthsModel.All
                        ? { color: 'white' }
                        : { color: 'black' },
                    ]"
                  >
                    {{ $t("November") }}</span
                  >
                </b-button>
                <b-button
                  size="small"
                  style="border-radius: 34px; opacity: 1"
                  :variant="
                    monthsModel.December || monthsModel.All
                      ? 'primary'
                      : 'white'
                  "
                  :style="[
                    monthsModel.December
                      ? {}
                      : { border: ' 2px solid #1F1F1F' },
                  ]"
                  @click="
                    monthsModel.December = !monthsModel.December;
                    monthsModel.All = false;
                    viewMonth();
                  "
                  class="m-1"
                  ><span
                    :style="[
                      monthsModel.December || monthsModel.All
                        ? { color: 'white' }
                        : { color: 'black' },
                    ]"
                  >
                    {{ $t("December") }}</span
                  >
                </b-button>
              </b-row>
              <!-- year -->
              <b-row class="mt-1">
                <b-col cols="12" md="4">
                  <p style="font-weight: bold; color: #454a62">
                    {{ $t("ChooseYear") }}*
                  </p>
                </b-col>
                <b-col cols="12" md="6">
                  <datepicker
                    :minimumView="'year'"
                    v-model="model.Year"
                    format="yyyy"
                  ></datepicker>
                </b-col>
              </b-row>
            </b-col>
          </b-card>
          <b-card>
            <b-row class="m-1">
              <b-col cols="12" md="4">
                <p style="font-weight: bold; color: #454a62">
                  {{ $t("From") }}
                </p>
              </b-col>
              <b-col cols="12" md="6">
                <vs-input type="time" v-model="model.TimeFrom"></vs-input>
              </b-col>
            </b-row>
            <b-row class="m-1">
              <b-col cols="12" md="4">
                <p style="font-weight: bold; color: #454a62">
                  {{ $t("To") }}
                </p>
              </b-col>
              <b-col cols="12" md="6">
                <vs-input type="time" v-model="model.TimeTo"></vs-input>
              </b-col>
            </b-row>
            <div>
              <p>{{ $t("AddAvailabilityHint") }}</p>
            </div>
          </b-card>

          <b-row class="pl-2 pr-2">
            <u
              class="m-2"
              style="
                color: #454a62;
                font-weight: bold;
                text-align: center;
                text-decoration: underline;
              "
              @click="PreviewOnCalendar()"
              >{{ $t("PreviewOnCalendar") }}</u
            >
            <b-button
              style="border-radius: 34px; opacity: 2; height: 35px"
              variant="primary"
              v-if="
                DoctorSessions.length > 0 && validSessionCalendar && saveFlag
              "
              @click="Save"
              class="m-1"
              :disabled="!validSessionCalendar"
              >{{ $t("Save") }}</b-button
            >
          </b-row>
        </b-col>
        <b-col cols="12" md="8">
          <b-card>
            <!-- <div class="vx-row">
                
              </div> -->
            <p
              class="mr-6"
              style="font-weight: bold; color: #454a62"
              v-if="
                DoctorSessions != null &&
                DoctorSessions.length > 0 &&
                DoctorSessions[midOfDoctorSessionList].Price != null &&
                DoctorSessions[midOfDoctorSessionList].Price != 0
              "
            >
              {{ $t("DoctorPrice") }} :
              <label style="font-weight: normal">
                {{ DoctorSessions[midOfDoctorSessionList].Price }}
                <span v-if="DoctorSessions[midOfDoctorSessionList].Currency">{{
                  DoctorSessions[midOfDoctorSessionList].Currency.NameEN
                }}</span>
              </label>
            </p>
            <b-button
              style="border-radius: 34px; opacity: 2; height: 35px"
              color="#76A224"
              class="m-1"
              @click="showYearSummary"
              >{{ $t("ShowYearSummary") }}</b-button
            >
            <FullCalendar
              defaultView="dayGridMonth"
              ref="fullCalendar"
              selectable="false"
              :disabled="true"
              :options="configOptions"
              editable="false"
            />
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <b-button type="border" color="danger" @click="$router.go(-1)">{{
            $t("Back")
          }}</b-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { domain } from "@/gloabelConstant.js";

import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import moduleCurrency from "@/store/settings/currency/moduleCurrency.js";
import "@fullcalendar/core/vdom"; // solve problem with Vite
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import FullCalendar from "@fullcalendar/vue";
import {
BAlert,
BButton,
BCard,
BCardBody,
BCardText,
BCol,
BFormTextarea,
BLink,
BRow,
BTableLite
} from "bootstrap-vue";
import Datepicker from "vuejs-datepicker";

export default {
  data() {
    return {
      EnglishLan: false,
      saveFlag: false,
      baseURL: domain,
      previewMode: false,
      calendarPlugins: [
        dayGridPlugin,
        interactionPlugin,
        // listPlugin,
        timeGridPlugin,
      ],
      priceModel: {},
      DoctorSessions: [],
      midOfDoctorSessionList: 0,
      model: {
        Sunday: false,
        Saturday: false,
        Friday: false,
        Thursday: false,
        Wednesday: false,
        Tuesday: false,
        Monday: false,
        Months: [],
      },
      monthsModel: {
        All: false,
        January: false,
        February: false,
        Mars: false,
        April: false,
        May: false,
        June: false,
        July: false,
        August: false,
        September: false,
        October: false,
        November: false,
        December: false,
      },
      SearchModel: {
        DoctorID: this.$store.state.AppActiveUser.Doctor.ID,
        SetPrice: true,
      },
      calendarStart: null,
      dates: ["2021-07-01", "2021-07-06"],
      isMultiSelection: true,
      values: [
        new Date("7/7/2021"),
        new Date("15/7/2021"),
        new Date("9/7/2021"),
      ],
    };
  },
  components: {
    FullCalendar,
    dayGridPlugin,
    // listPlugin,
    interactionPlugin,
    timeGridPlugin,
    Datepicker,
    BAlert,
    BButton,
    BCard,
    BCardBody,
    BCardText,
    BCol,
    BFormTextarea,
    BLink,
    BRow,
    BTableLite,
  },
  computed: {
    doctorSessions() {
      return this.DoctorSessions;
    },
    formatDate(year) {
      return year ? moment(year).format("yyyy") : "";
    },
    configOptions() {
      return {
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        events: this.DoctorSessions,
        datesSet: this.handleMonthChange,
        weekends: this.weekendsVisible,
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        },
        initialView: "dayGridMonth",
      };
    },
    validSessionCalendar() {
      debugger;
      return (
        this.model.TimeFrom != undefined &&
        this.model.TimeTo != undefined &&
        this.priceModel.Price > 0 &&
        this.priceModel.CurrencyID > 0 &&
        (this.model.Sunday ||
          this.model.Monday ||
          this.model.Tuesday ||
          this.model.Wednesday ||
          this.model.Thursday ||
          this.model.Friday ||
          this.model.Saturday)
      );
    },
    currencies() {
      return this.$store.state.CurrencyList.currencies;
    },
  },
  methods: {
    showYearSummary() {
      window.open("DoctorYearSummaryAppointments", "_blank");
    },
    getDoctorSessions() {
      this.DoctorSessions = [];
      this.$vs.loading();
      this.$store
        .dispatch("DoctorList/SimpleSearchDoctorSessions", this.SearchModel)
        .then((res) => {
          this.DoctorSessions = res.data.Data;
          this.$vs.loading.close();
          debugger;
          this.midOfDoctorSessionList = this.DoctorSessions.length;
          if (this.DoctorSessions.length == 1) {
            this.midOfDoctorSessionList = 0;
          } else if (this.DoctorSessions.length % 2 == 0) {
            this.midOfDoctorSessionList = this.midOfDoctorSessionList / 2;
          } else {
            this.midOfDoctorSessionList = (this.midOfDoctorSessionList + 1) / 2;
          }
          debugger;
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    formatJsDate(dateToFormat) {
      debugger;
      return (
        dateToFormat.getFullYear() +
        "-" +
        (dateToFormat.getMonth() + 1) +
        "-" +
        dateToFormat.getDate()
      );
    },
    handleMonthChange(arg) {
      debugger;
      if (this.calendarStart != arg.startStr && !this.previewMode) {
        this.calendarStart = arg.startStr;
        this.SearchModel.DateFrom = this.formatJsDate(arg.view.activeStart);
        this.SearchModel.DateTo = this.formatJsDate(arg.view.activeEnd);
        this.getDoctorSessions();
      }
    },
    back() {
      this.$router.go(-1);
    },
    getSesionPrice() {
      this.$vs.loading();

      this.$store
        .dispatch(
          "DoctorList/GetDoctorSessionPriceByDoctorID",
          this.$store.state.AppActiveUser.Doctor.ID
        )
        .then((res) => {
          if (res.status == 200) {
            this.priceModel = res.data.Data;
            this.$vs.loading.close();
          }
        })
        .catch(() => {
          this.$vs.loading.close();

          window.showError();
        });
    },
    clearModel() {
      this.Model = {
        Sunday: false,
        Saturday: false,
        Friday: false,
        Thursday: false,
        Wednesday: false,
        Tuesday: false,
        Monday: false,
        Months: [],
      };
      this.monthsModel = {
        All: false,
        January: false,
        February: false,
        Mars: false,
        April: false,
        May: false,
        June: false,
        July: false,
        August: false,
        September: false,
        October: false,
        November: false,
        December: false,
      };
    },
    Save() {
      debugger;
      this.changeMonthesStringToNumberList();
      debugger;
      this.$vs.loading();
      this.model.DoctorID = this.$store.state.AppActiveUser.Doctor.ID;
      this.model.Price = this.priceModel.Price;
      this.model.CurrencyID = this.priceModel.CurrencyID;
      this.previewMode = false;
      this.$store
        .dispatch("DoctorList/SaveDoctorSession", this.model)
        .then((res) => {
          if (res.status == 200) {
            debugger;
            this.$vs.loading.close();
            this.SaveDoctorSessionPrice();
            this.clearModel();
            window.showAddSuccess();
            this.$router.go(-1);
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          window.showError();
        });
    },
    SaveDoctorSessionPrice() {
      this.$store
        .dispatch("DoctorList/SaveDoctorSessionPrice", this.priceModel)
        .then((res) => {
          if (res.status == 200) {
            this.$vs.loading.close();
          }
        })
        .catch((err) => {
          this.$vs.loading.close();

          window.showError();
          window.showError(err.response.data.message);
        });
    },
    initAllMonthes() {
      if (this.monthsModel.All == false) {
        this.monthsModel = {
          All: true,
          January: false,
          February: false,
          Mars: false,
          April: false,
          May: false,
          June: false,
          July: false,
          August: false,
          September: false,
          October: false,
          November: false,
          December: false,
        };
      } else {
        this.monthsModel.All = false;
      }
      this.viewMonth();
    },
    changeMonthesStringToNumberList() {
      debugger;
      this.model.Months = [];
      if (this.monthsModel.All == true) {
        this.model.Months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      } else {
        if (this.monthsModel.January == true) {
          this.model.Months.push(1);
        }
        if (this.monthsModel.February == true) {
          this.model.Months.push(2);
        }
        if (this.monthsModel.Mars == true) {
          this.model.Months.push(3);
        }
        if (this.monthsModel.April == true) {
          this.model.Months.push(4);
        }
        if (this.monthsModel.May == true) {
          this.model.Months.push(5);
        }
        if (this.monthsModel.June == true) {
          this.model.Months.push(6);
        }
        if (this.monthsModel.July == true) {
          this.model.Months.push(7);
        }
        if (this.monthsModel.August == true) {
          this.model.Months.push(8);
        }
        if (this.monthsModel.September == true) {
          this.model.Months.push(9);
        }
        if (this.monthsModel.October == true) {
          this.model.Months.push(10);
        }
        if (this.monthsModel.November == true) {
          this.model.Months.push(11);
        }
        if (this.monthsModel.December == true) {
          this.model.Months.push(12);
        }
      }
    },
    PreviewOnCalendar() {
      debugger;
      if (this.validSessionCalendar) {
        debugger;
        this.changeMonthesStringToNumberList();
        debugger;

        this.$vs.loading();
        this.saveFlag = true;
        this.model.Price = this.priceModel.Price;
        this.model.CurrencyID = this.priceModel.CurrencyID;
        var vm = this;
        this.$store
          .dispatch("DoctorList/PreviewOnCalendar", this.model)
          .then((res) => {
            if (res.status == 200) {
              debugger;
              vm.DoctorSessions = res.data.Data.Sessions;
              if (vm.DoctorSessions.length > 0) {
                vm.previewMode = true;

                vm.midOfDoctorSessionList = vm.DoctorSessions.length;
                if (vm.DoctorSessions.length == 1) {
                  vm.midOfDoctorSessionList = 0;
                } else if (vm.DoctorSessions.length % 2 == 0) {
                  vm.midOfDoctorSessionList = vm.midOfDoctorSessionList / 2;
                } else {
                  vm.midOfDoctorSessionList =
                    (vm.midOfDoctorSessionList + 1) / 2;
                }
              }
              debugger;

              this.$vs.loading.close();
            }
          })
          .catch(() => {
            this.$vs.loading.close();

            window.showError();
          });
      }
    },
    viewMonth() {
      var vm = this;

      debugger;
      this.changeMonthesStringToNumberList();
      //this.monthsModel;

      if (this.model.Months.length == 1 && this.model.Year != null) {
        debugger;
        var goTo = new Date(
          this.model.Year.getFullYear(),
          this.model.Months[0] - 1,
          1
        );
        debugger;
        let calendarApi = this.$refs.fullCalendar.getApi();
        calendarApi.gotoDate(goTo);
        debugger;
      } else if (this.model.Months.length == 12 && this.model.Year != null) {
        var goTo = new Date(this.model.Year.getFullYear(), 0, 1);
        debugger;
        let calendarApi = this.$refs.fullCalendar.getApi();
        calendarApi.gotoDate(goTo);
        debugger;
      }
    },
    //  checkdates(ev){
    //    debugger
    //    var selectedIndex =  ev.sender.value()
    // 		// var selectedItem = ev.sender.dataItem(selectedIndex).toJSON();
    // 		this.dates = selectedIndex;
    //  },
    //   onNavigate: function(e) {
    //        debugger
    //    this.dates = null;
    //     var view = e.sender.view();
    //     console.log(view.name); //the name of the current view
    //     var current = e.sender.current();
    //     console.log(current); //currently the focused date
    //   }
  },
  created() {
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" ||
      localStorage.getItem("SaveLang") == null
        ? true
        : false;
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    if (!moduleCurrency.isRegistered) {
      this.$store.registerModule("CurrencyList", moduleCurrency);
      moduleCurrency.isRegistered = true;
    }

    this.$store.dispatch("CurrencyList/GetAllCurrencies");
    this.getSesionPrice();
  },

  mounted() {
    var vm = this;
    vm.$refs.fullCalendar.getApi().next();
    setTimeout(function () {
      //if(vm.DoctorSessions==null||vm.DoctorSessions.length==0)
      {
        vm.$refs.fullCalendar.getApi().prev();
      }
    }, 500);
  },
};
</script>
<style lang='scss' id="style">
</style>
<style>
.wrap {
  margin: 0px auto;
  max-width: 250px;
}
</style>
